<stx-generic-page>
  <div *ngIf="treatmentForReviewExists; else noTreatmentsToBeReviewed">
    <div *ngIf="isNeededDataLoaded">
      <stx-form-panel>
        <div class="form-columns-2-1">
          <div>
            <div class="form-row">
              <div class="form-columns-1-1">
                <stx-readonly-media-group [mediaSets]="photos"></stx-readonly-media-group>
              </div>
            </div>
            <div class="form-row">
              <stx-readonly-media-group [mediaSets]="otherPhotos"></stx-readonly-media-group>
            </div>
          </div>
          <div>
            <stx-grading-form
              [gradingTitle]="gradingTitle"
              [preGrades]="preGrades"
              [postGrades]="postGrades"
              [failPassGrades]="failGrades"
              [numberOfPendingCasesForReviewer]="informationAboutNextCaseToReview.numberOfPendingCasesForReviewer"
              [isPalateTreatment]="isCurrentTreatmentPalateFistulaOrAbg"
              [submitSurgicalReviewWsCall]="submitSurgicalReviewWsCall"
            ></stx-grading-form>
          </div>
        </div>
      </stx-form-panel>

      <stx-form-panel>
        <div class="layout-columns-1-1-1">
          <stx-surgery-details
            [operationDescription]="informationAboutNextCaseToReview.surgery"
            [patientAge]="patientAge(informationAboutNextCaseToReview.surgery.patientAgeInMonths)"
          ></stx-surgery-details>
          <hr class="vertical-separator" />
          <stx-historic-treatments-details
            [patientTreatmentsForReview]="informationAboutNextCaseToReview.treatmentsHistory"
          ></stx-historic-treatments-details>
          <hr class="vertical-separator" />
          <stx-historic-diagnoses-details [diagnoses]="informationAboutNextCaseToReview.diagnoses"></stx-historic-diagnoses-details>
        </div>
      </stx-form-panel>
    </div>
  </div>

  <ng-template #noTreatmentsToBeReviewed>
    <stx-form-panel>
      {{ 'surgical_qa_panel.no_pending_treatments' | translate }}
    </stx-form-panel>
  </ng-template>
</stx-generic-page>
