import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { MediaResource } from '@shared/models/media-resource.model';
import { ageMonthToYears } from '@utils/date.utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DictionaryTranslation } from '@src/app/features/patient/models/patient.model';
import { ReviewService } from '@src/app/features/review/review.service';
import { CompleteReviewInformation } from '@src/app/features/review/surgical/models/complete-review-information.model';
import { ReviewUpdate } from '@src/app/features/review/surgical/models/review-update.model';
import { WsHelperService } from '@shared/services/ws-helper.service';
import {
  MediaResourceWithLabels,
  MediaSet
} from '@shared/modules/readonly-commons/components/readonly-media-group/readonly-media-group.model';

@Component({
  templateUrl: './surgical-review-page.component.html'
})
export class SurgicalReviewPageComponent extends BaseComponent implements OnInit {
  readonly palateTreatment = 'palate';
  readonly abg = 'alveolar_bone_graft';
  readonly fistula = 'fistula';
  readonly preSurgicalGrades = 'preSurgicalGrades';
  readonly postSurgicalGrades = 'postSurgicalGrades';
  readonly failPassGrades = 'failPassGrades';
  readonly preSurgicalPhotoLabel = 'surgical_qa_panel.pre';
  readonly postSurgicalPhotoLabel = 'surgical_qa_panel.post';
  readonly gradingTitleInterventionTypeMap = new Map()
    .set('palate', 'surgical_qa_panel.please_grade_palate')
    .set('lip_bilateral', 'surgical_qa_panel.please_grade_lip_bilateral')
    .set('lip_unilateral', 'surgical_qa_panel.please_grade_lip_unilateral')
    .set('palate_secondary', 'surgical_qa_panel.please_grade_secondary_palate')
    .set('alveolar_bone_graft', 'surgical_qa_panel.please_grade_abg')
    .set('lip_nose_revision', 'surgical_qa_panel.please_grade_lip_nose_revision');

  informationAboutNextCaseToReview: CompleteReviewInformation;
  preGrades: DictionaryTranslation[];
  postGrades: DictionaryTranslation[];
  failGrades: DictionaryTranslation[];
  isNeededDataLoaded = false;
  treatmentForReviewExists = true;
  isCurrentTreatmentPalateFistulaOrAbg: boolean;
  photos: MediaSet[];
  otherPhotos: MediaSet[];
  gradingTitle: string;
  trialCase: boolean;

  constructor(
    private reviewService: ReviewService,
    private changeRef: ChangeDetectorRef,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  displayNextTrialCase(data: CompleteReviewInformation, trialCase: boolean): void {
    this.informationAboutNextCaseToReview = data;
    this.trialCase = trialCase;
    this.wsHelperService.call(this.reviewService.getStaticDictionaries()).subscribe(dictionary => {
      this.setDictionaries(dictionary);
      this.isNeededDataLoaded = true;
      this.reloadTreatmentData(data);
    });
  }

  ngOnInit(): void {
    this.wsHelperService.callWithSpinner(this.reviewService.getNextSurgicalCase()).subscribe(data => {
      if (data) {
        this.displayNextTrialCase(data, false);
        return;
      }
      this.wsHelperService.callWithSpinner(this.reviewService.getNextTrialSurgicalCase()).subscribe(trialData => {
        if (!trialData) {
          this.treatmentForReviewExists = false;
          this.changeRef.detectChanges();
        } else {
          this.displayNextTrialCase(trialData, true);
        }
      });
    });
  }

  displayNextCase(data: CompleteReviewInformation, trialCase: boolean): void {
    this.informationAboutNextCaseToReview = data;
    this.reloadTreatmentData(data);
    this.trialCase = trialCase;
    this.changeRef.detectChanges();
  }

  submitSurgicalReviewWsCall: (event: ReviewUpdate) => Observable<void> = event => {
    return this.wsHelperService
      .callWithSpinner(this.reviewService.submitSurgicalReview(this.informationAboutNextCaseToReview.treatmentId, event, this.trialCase))
      .pipe(
        tap(() => {
          this.wsHelperService.callWithSpinner(this.reviewService.getNextSurgicalCase()).subscribe(nextCaseData => {
            if (nextCaseData) {
              this.displayNextCase(nextCaseData, false);
              return;
            }
            this.wsHelperService.callWithSpinner(this.reviewService.getNextTrialSurgicalCase()).subscribe(nextTrialCaseData => {
              if (!nextTrialCaseData) {
                this.treatmentForReviewExists = false;
              } else {
                this.displayNextCase(nextTrialCaseData, true);
              }
            });
          });
        })
      );
  };

  patientAge(patientAgeInMonths: number): string {
    return ageMonthToYears(patientAgeInMonths);
  }

  private getOtherPhotos(): MediaSet[] {
    return [
      {
        mediaWithLabels: this.informationAboutNextCaseToReview.surgery.otherPhotos
          .reverse()
          .slice(1)
          .map(mediaResource => {
            return {
              ...mediaResource
            };
          })
      }
    ];
  }

  private reloadTreatmentData(nextCaseData: CompleteReviewInformation) {
    this.gradingTitle = this.gradingTitleInterventionTypeMap.get(nextCaseData.surgery?.surgeryReviewTitle);
    this.isCurrentTreatmentPalateFistulaOrAbg = this.checkIfTheQaReviewIsPalateOrAbgRelated(nextCaseData?.surgery?.surgeryReviewTitle);
    this.photos = this.getPhotos(nextCaseData.surgery.prePhotos, nextCaseData.surgery.postPhotos);
    this.otherPhotos = this.getOtherPhotos();
    this.changeRef.detectChanges();
  }

  private setDictionaries(dictionary: Map<string, DictionaryTranslation[]>) {
    this.preGrades = dictionary.get(this.preSurgicalGrades);
    this.postGrades = dictionary.get(this.postSurgicalGrades);
    this.failGrades = dictionary.get(this.failPassGrades);
  }

  private checkIfTheQaReviewIsPalateOrAbgRelated(gradingTitle: string): boolean {
    return [this.palateTreatment, this.abg].some(treatmentType => gradingTitle.includes(treatmentType));
  }

  private getPhotos(prePhotos: MediaResource[], postPhotos: MediaResource[]): MediaSet[] {
    return [
      {
        mediaWithLabels: this.getPhotosForParentOrderName(
          prePhotos,
          ParentOrderName.OPERATION_PRE_INTRA_ORAL,
          ParentOrderName.OPERATION_PRE_FRONTAL,
          this.preSurgicalPhotoLabel
        ).concat(
          this.getPhotosForParentOrderName(
            postPhotos,
            ParentOrderName.OPERATION_POST_INTRA_ORAL,
            ParentOrderName.OPERATION_POST_FRONTAL,
            this.postSurgicalPhotoLabel
          )
        )
      }
    ];
  }

  private getPhotosForParentOrderName(
    prePhotos: MediaResource[],
    preIntraParentOrderName: ParentOrderName,
    postIntraParentOrderName: ParentOrderName,
    label: string
  ): MediaResourceWithLabels[] {
    if (this.isCurrentTreatmentPalateFistulaOrAbg) {
      return this.getMediaResourceWithLabels(prePhotos, preIntraParentOrderName, label);
    } else {
      return this.getMediaResourceWithLabels(prePhotos, postIntraParentOrderName, label);
    }
  }

  private getMediaResourceWithLabels(
    prePhotos: MediaResource[],
    parentOrderName: ParentOrderName,
    label: string
  ): MediaResourceWithLabels[] {
    return prePhotos
      .filter(photo => photo.parentOrderName === parentOrderName)
      .map(mediaResource => {
        return {
          labelKeys: [label],
          ...mediaResource
        };
      });
  }
}
