<ng-container *ngFor="let surveyBlock of partnerSurveyBlocks$ | async; last as isLast">
  <stx-data-block [header]="'dashboard.survey.header' | translate" [light]="true">
    <div class="data-spaced-column dashboard-announcement">
      <div *stxContentFromHtmlFile="staticFilesRegistry.partner_survey"></div>
    </div>
    <div class="flex-row-container flex-center flex-none-important">
      <span class="m-r-4">
        <strong>{{ 'dashboard.survey.button' | translate }}</strong>
      </span>
      <a color="primary" rel="noopener noreferrer" target="_blank" [href]="surveyBlock.link">
        {{ surveyBlock.link }}
      </a>
    </div>
    <div class="m-t-10">
      <p>{{ 'dashboard.survey.text.deadline' | translate }} {{ surveyBlock.endDate | date : 'yyyy-MM-dd' }}.</p>
    </div>
  </stx-data-block>
  <div class="m-b-15" *ngIf="isLast"></div>
</ng-container>

<ng-container *ngFor="let surveyBlock of partnerTcsSurveyBlocks$ | async; last as isLast">
  <stx-data-block [header]="'dashboard.survey.tcs.header' | translate" [light]="true">
    <div class="data-spaced-column dashboard-announcement">
      <div *stxContentFromHtmlFile="staticFilesRegistry.partner_tcs_survey"></div>
    </div>
    <div>
      <ul>
        <ng-container *ngFor="let tcSurveyLink of surveyBlock.treatmentCenterSurveyLinks">
          <li>
            <a rel="noopener noreferrer" target="_blank" [href]="tcSurveyLink.link">
              <div class="flex-row-container flex-center">
                <span>
                  {{ tcSurveyLink.displayName }}
                </span>
                <mat-icon class="inline-mat-icon p-l-5">open_in_new</mat-icon>
              </div>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
    <div>
      <p>
        {{ 'dashboard.survey.text.deadline' | translate }} {{ surveyBlock.treatmentCenterSurveyLinks[0].endDate | date : 'yyyy-MM-dd' }}
      </p>
    </div>
  </stx-data-block>
  <div class="m-b-15" *ngIf="isLast"></div>
</ng-container>
