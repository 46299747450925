import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'stx-checkbox-section',
  templateUrl: './checkbox-section.component.html',
  styleUrls: ['./checkbox-section.component.scss']
})
export class CheckboxSectionComponent extends BaseComponent implements OnInit {
  private _expanded: boolean = false;

  @Input()
  controlName: string;

  @Input()
  label: string;

  @Input()
  print: boolean;

  constructor(private readonly controlContainer: ControlContainer) {
    super();
  }

  private control: AbstractControl;

  ngOnInit(): void {
    const formGroup = <UntypedFormGroup>this.controlContainer.control;
    this.control = formGroup.get(this.controlName);
    this._expanded = !!this.control.value;
    this.subSink.sink = this.control.valueChanges.subscribe(newValue => {
      this._expanded = !!newValue;
    });
  }

  set expanded(expanded: boolean) {
    this._expanded = expanded;
    this.control.markAsTouched();
    this.control.setValue(expanded);
    if (!expanded) {
      this.control.reset();
      this.control.markAsTouched();
    }
  }

  get expanded(): boolean {
    return this.print || this._expanded;
  }

  get icon(): IconDefinition {
    return this.expanded ? faChevronUp : faChevronDown;
  }
}
