import { AnesthesiologistSelectComponent } from '@/src/app/features/surgical/components/anesthesiologist-select/anesthesiologist-select.component';
import { PartialValidationConfig } from '@/src/app/shared/validation/partial-validation-config.model';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ParentOrderNameImageConfig } from '@shared/components/form/components/image-group/image-group.model';
import { ParentOrderName } from '@shared/enums/parent-order-name.enum';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { atLeastOneRequired, requiredIfValidator } from '@shared/validation/validators';
import { SurgicalIntervention } from '@src/app/features/surgical/models/surgical-intervention.model';
import {
  FistulaRepairType,
  LipNoseRevisionRepairType,
  PrimaryCleftPalateRepairType,
  PrimaryLipNoseBilateralRepairType,
  PrimaryLipNoseUnilateralRepairType
} from '@src/app/features/surgical/surgical.enum';
import { chinaCountryId, indiaCountryId, philippinesCountryId } from '@utils/constants.utils';
import { STX_MOMENT_DATE_FORMATS } from '@utils/date.utils';
import { FormMediaUtils } from '@utils/form-media.utils';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Mime } from '@utils/file.utils';

function validValue$(control: AbstractControl): Observable<any> {
  return control.valueChanges.pipe(filter(() => control.valid));
}

interface MainControls {
  admissionDate: UntypedFormControl;
  operationDate: UntypedFormControl;
  dischargeDate: UntypedFormControl;
  followVisitDate: UntypedFormControl;
  operationTime: UntypedFormControl;
  weight: UntypedFormControl;
  height: UntypedFormControl;
  treatmentCenterId: UntypedFormControl;
  practitionerId: UntypedFormControl;
  anesthMethod: UntypedFormControl;
  [AnesthesiologistSelectComponent.idCtrlName]: UntypedFormControl;
  philHealth: UntypedFormControl;
  complicationAny: UntypedFormControl;
  complicationInjury: UntypedFormControl;
  complicationBreathingProblems: UntypedFormControl;
  complicationDehiscence: UntypedFormControl;
  complicationFistula: UntypedFormControl;
  complicationBackToOr: UntypedFormControl;
  chinaSupport: UntypedFormControl;
  chinaSupportOrphan: UntypedFormControl;
  chinaSupportMigrant: UntypedFormControl;
  chinaSupportRegionNofund: UntypedFormControl;
  additionalComment: UntypedFormControl;
  primaryLipNoseUnilateral: UntypedFormControl;
  primaryLipNoseUnilateralRepairType: UntypedFormControl;
  primaryLipNoseBilateral: UntypedFormControl;
  primaryLipNoseBilateralRepairType: UntypedFormControl;
  primaryCleftPalate: UntypedFormControl;
  primaryCleftPalateRepairType: UntypedFormControl;
  fistula: UntypedFormControl;
  fistulaRepairType: UntypedFormControl;
  lipNoseRevision: UntypedFormControl;
  lipNoseRevisionRepairType: UntypedFormControl;
  alveolarBoneGraft: UntypedFormControl;
  alveolarBoneGraftRepairType: UntypedFormControl;
  velopharyngealDisfunction: UntypedFormControl;
  velopharyngealDisfunctionRepairType: UntypedFormControl;
  repairComment: UntypedFormControl;
}

interface AnesthesiaMediaControls {
  [ParentOrderName.ANESTHESIA_INTRA_OPERATIVE]: UntypedFormControl;
  [ParentOrderName.ANESTHESIA_PRE_OPERATIVE]: UntypedFormControl;
  [ParentOrderName.ANESTHESIA_POST_OPERATIVE]: UntypedFormControl;
}

interface PhotoControls {
  [ParentOrderName.OPERATION_PRE_FRONTAL]: UntypedFormControl;
  [ParentOrderName.OPERATION_POST_FRONTAL]: UntypedFormControl;
  [ParentOrderName.OPERATION_PRE_NASAL_BASE]: UntypedFormControl;
  [ParentOrderName.OPERATION_POST_NASAL_BASE]: UntypedFormControl;
  [ParentOrderName.OPERATION_PRE_CLEFT_SIDE]: UntypedFormControl;
  [ParentOrderName.OPERATION_POST_CLEFT_SIDE]: UntypedFormControl;
  [ParentOrderName.OPERATION_PRE_INTRA_ORAL]: UntypedFormControl;
  [ParentOrderName.OPERATION_POST_INTRA_ORAL]: UntypedFormControl;
  [ParentOrderName.OPERATION_FRONTAL]: UntypedFormControl;
  [ParentOrderName.OPERATION_FOLLOW_UP]: UntypedFormControl;
}

type InterventionType = keyof MainControls &
  (
    | 'primaryLipNoseUnilateral'
    | 'primaryLipNoseBilateral'
    | 'primaryCleftPalate'
    | 'fistula'
    | 'lipNoseRevision'
    | 'alveolarBoneGraft'
    | 'velopharyngealDisfunction'
  );

type RepairType = keyof MainControls &
  (
    | 'primaryLipNoseUnilateralRepairType'
    | 'primaryLipNoseBilateralRepairType'
    | 'primaryCleftPalateRepairType'
    | 'fistulaRepairType'
    | 'lipNoseRevisionRepairType'
    | 'alveolarBoneGraftRepairType'
    | 'velopharyngealDisfunctionRepairType'
  );

export const interventionTypeControlNames: InterventionType[] = [
  'primaryLipNoseUnilateral',
  'primaryLipNoseBilateral',
  'primaryCleftPalate',
  'fistula',
  'lipNoseRevision',
  'alveolarBoneGraft',
  'velopharyngealDisfunction'
];

export const repairTypeControlNames: RepairType[] = [
  'primaryLipNoseUnilateralRepairType',
  'primaryLipNoseBilateralRepairType',
  'primaryCleftPalateRepairType',
  'fistulaRepairType',
  'lipNoseRevisionRepairType',
  'alveolarBoneGraftRepairType',
  'velopharyngealDisfunctionRepairType'
];

function exclusiveInterventionTypesFor(key: InterventionType): InterventionType[] {
  switch (key) {
    case 'primaryLipNoseBilateral':
      return ['primaryLipNoseUnilateral', 'lipNoseRevision'];
    case 'primaryLipNoseUnilateral':
      return ['primaryLipNoseBilateral', 'lipNoseRevision'];
    case 'lipNoseRevision':
      return ['primaryLipNoseBilateral', 'primaryLipNoseUnilateral'];
    case 'primaryCleftPalate':
      return ['velopharyngealDisfunction', 'fistula'];
    case 'fistula':
      return ['primaryCleftPalate'];
    case 'velopharyngealDisfunction':
      return ['primaryCleftPalate'];
    default:
      return [];
  }
}

const chinaSupportFields: Array<keyof MainControls> = ['chinaSupportOrphan', 'chinaSupportMigrant', 'chinaSupportRegionNofund'];

function anyControlHasValue(controls: AbstractControl[]): boolean {
  return controls.find(control => !!control.value) !== undefined;
}

export const imageConfigs: ParentOrderNameImageConfig[] = [
  { label: 'order_name.pre_operation_frontal', parentOrderName: ParentOrderName.OPERATION_PRE_FRONTAL },
  { label: 'order_name.post_operation_frontal', parentOrderName: ParentOrderName.OPERATION_POST_FRONTAL },
  { label: 'order_name.pre_nasal_base', parentOrderName: ParentOrderName.OPERATION_PRE_NASAL_BASE },
  { label: 'order_name.post_nasal_base', parentOrderName: ParentOrderName.OPERATION_POST_NASAL_BASE },
  { label: 'order_name.pre_cleft_side_lateral', parentOrderName: ParentOrderName.OPERATION_PRE_CLEFT_SIDE },
  { label: 'order_name.post_cleft_side_lateral', parentOrderName: ParentOrderName.OPERATION_POST_CLEFT_SIDE },
  { label: 'order_name.pre_operation_intra_oral', parentOrderName: ParentOrderName.OPERATION_PRE_INTRA_ORAL },
  { label: 'order_name.post_operation_intra_oral', parentOrderName: ParentOrderName.OPERATION_POST_INTRA_ORAL },
  { label: 'order_name.frontal_smiling', parentOrderName: ParentOrderName.OPERATION_FRONTAL },
  { label: 'order_name.follow_up_visit', parentOrderName: ParentOrderName.OPERATION_FOLLOW_UP }
];

const parentOrderNames = imageConfigs.map(config => config.parentOrderName);

export const anesthesiaMediaAcceptedTypes = [Mime.GIF, Mime.JPEG, Mime.JPG, Mime.PNG, Mime.PDF];

export const anesthesiaMediaConfigs: ParentOrderNameImageConfig[] = [
  {
    label: 'order_name.anesthesia_intra_operative',
    parentOrderName: ParentOrderName.ANESTHESIA_INTRA_OPERATIVE
  },
  {
    label: 'order_name.anesthesia_pre_operative',
    parentOrderName: ParentOrderName.ANESTHESIA_PRE_OPERATIVE
  },
  {
    label: 'order_name.anesthesia_post_operative',
    parentOrderName: ParentOrderName.ANESTHESIA_POST_OPERATIVE
  }
];

const anesthesiaMediaParentOrderNames = anesthesiaMediaConfigs.map(config => config.parentOrderName);

export class SurgicalInterventionForm {
  static readonly fieldsCheckedOnSave: string[] = [
    'operationDate',
    'treatmentCenterId',
    'primaryLipNoseUnilateralRepairType',
    'primaryLipNoseBilateralRepairType',
    'primaryCleftPalateRepairType',
    'fistulaRepairType',
    'lipNoseRevisionRepairType',
    'alveolarBoneGraftRepairType',
    'velopharyngealDisfunctionRepairType',
    'repairComment',
    ...interventionTypeControlNames
  ];
  readonly formGroup: UntypedFormGroup;
  readonly mainControls: Readonly<MainControls>;
  readonly photoControls: Readonly<PhotoControls>;
  readonly anesthesiaMediaControls: Readonly<AnesthesiaMediaControls>;
  readonly indiaDeclarationForm: UntypedFormControl;
  readonly treatmentCenter$: Observable<TreatmentCenterDictionary | null>;
  readonly saveValidationConfig: PartialValidationConfig;

  private treatmentCenter: TreatmentCenterDictionary;

  constructor(treatmentCenters: Observable<TreatmentCenterDictionary[]>) {
    this.mainControls = {
      admissionDate: new UntypedFormControl(null, StxValidators.required),
      operationDate: new UntypedFormControl(null, { updateOn: 'blur', validators: [StxValidators.required] }),
      dischargeDate: new UntypedFormControl(null, StxValidators.required),
      followVisitDate: new UntypedFormControl(null),
      operationTime: new UntypedFormControl(null, StxValidators.required),
      weight: new UntypedFormControl(null, {
        updateOn: 'blur',
        validators: [StxValidators.required, Validators.min(1), Validators.max(200)]
      }),
      height: new UntypedFormControl(null, [StxValidators.required, Validators.min(1), Validators.max(200)]),
      treatmentCenterId: new UntypedFormControl(null, StxValidators.required),
      practitionerId: new UntypedFormControl(null, StxValidators.required),
      anesthMethod: new UntypedFormControl(null, StxValidators.required),
      anesthesiologistId: new UntypedFormControl(null, StxValidators.required),
      philHealth: new UntypedFormControl(),
      complicationAny: new UntypedFormControl(false, StxValidators.required),
      complicationInjury: new UntypedFormControl(false, StxValidators.required),
      complicationBreathingProblems: new UntypedFormControl(),
      complicationDehiscence: new UntypedFormControl(),
      complicationFistula: new UntypedFormControl(),
      complicationBackToOr: new UntypedFormControl(),
      chinaSupport: new UntypedFormControl(null),
      chinaSupportOrphan: new UntypedFormControl(),
      chinaSupportMigrant: new UntypedFormControl(),
      chinaSupportRegionNofund: new UntypedFormControl(),
      additionalComment: new UntypedFormControl(),
      primaryLipNoseUnilateral: new UntypedFormControl(),
      primaryLipNoseUnilateralRepairType: new UntypedFormControl(
        null,
        requiredIfValidator(this.isSelectedPredicate('primaryLipNoseUnilateral'))
      ),
      primaryLipNoseBilateral: new UntypedFormControl(),
      primaryLipNoseBilateralRepairType: new UntypedFormControl(
        null,
        requiredIfValidator(this.isSelectedPredicate('primaryLipNoseBilateral'))
      ),
      primaryCleftPalate: new UntypedFormControl(),
      primaryCleftPalateRepairType: new UntypedFormControl(null, requiredIfValidator(this.isSelectedPredicate('primaryCleftPalate'))),
      fistula: new UntypedFormControl(),
      fistulaRepairType: new UntypedFormControl(null, requiredIfValidator(this.isSelectedPredicate('fistula'))),
      lipNoseRevision: new UntypedFormControl(),
      lipNoseRevisionRepairType: new UntypedFormControl(null, requiredIfValidator(this.isSelectedPredicate('lipNoseRevision'))),
      alveolarBoneGraft: new UntypedFormControl(),
      alveolarBoneGraftRepairType: new UntypedFormControl(null, requiredIfValidator(this.isSelectedPredicate('alveolarBoneGraft'))),
      velopharyngealDisfunction: new UntypedFormControl(),
      velopharyngealDisfunctionRepairType: new UntypedFormControl(
        null,
        requiredIfValidator(this.isSelectedPredicate('velopharyngealDisfunction'))
      ),
      repairComment: new UntypedFormControl(
        null,
        requiredIfValidator(
          () =>
            this.isNotLipTypeOtherOperation ||
            this.mainControls.primaryLipNoseUnilateralRepairType.value === PrimaryLipNoseUnilateralRepairType.OTHER ||
            this.mainControls.primaryLipNoseBilateralRepairType.value === PrimaryLipNoseBilateralRepairType.OTHER ||
            this.mainControls.lipNoseRevisionRepairType.value === LipNoseRevisionRepairType.OTHER
        )
      )
    };

    this.anesthesiaMediaControls = {
      [ParentOrderName.ANESTHESIA_PRE_OPERATIVE]: new UntypedFormControl(),
      [ParentOrderName.ANESTHESIA_INTRA_OPERATIVE]: new UntypedFormControl(null, StxValidators.required),
      [ParentOrderName.ANESTHESIA_POST_OPERATIVE]: new UntypedFormControl()
    };

    this.photoControls = {
      [ParentOrderName.OPERATION_PRE_FRONTAL]: new UntypedFormControl(null, StxValidators.required),
      [ParentOrderName.OPERATION_POST_FRONTAL]: new UntypedFormControl(
        null,
        requiredIfValidator(() => this.isLip)
      ),
      [ParentOrderName.OPERATION_PRE_NASAL_BASE]: new UntypedFormControl(),
      [ParentOrderName.OPERATION_POST_NASAL_BASE]: new UntypedFormControl(),
      [ParentOrderName.OPERATION_PRE_CLEFT_SIDE]: new UntypedFormControl(),
      [ParentOrderName.OPERATION_POST_CLEFT_SIDE]: new UntypedFormControl(),
      [ParentOrderName.OPERATION_PRE_INTRA_ORAL]: new UntypedFormControl(
        null,
        requiredIfValidator(() => this.isIntraOralMandatory)
      ),
      [ParentOrderName.OPERATION_POST_INTRA_ORAL]: new UntypedFormControl(
        null,
        requiredIfValidator(() => this.isIntraOralMandatory)
      ),
      [ParentOrderName.OPERATION_FRONTAL]: new UntypedFormControl(),
      [ParentOrderName.OPERATION_FOLLOW_UP]: new UntypedFormControl()
    };

    this.indiaDeclarationForm = new UntypedFormControl(
      null,
      requiredIfValidator(() => this.isIndianTcSelected)
    );

    this.treatmentCenter$ = combineLatest([
      treatmentCenters,
      this.mainControls.treatmentCenterId.valueChanges.pipe(distinctUntilChanged())
    ]).pipe(
      map(([tcList, tcId]) => {
        const match = tcList.find(tc => tc.id === tcId);
        if (match === undefined) {
          return null;
        }
        return match;
      })
    );
    this.treatmentCenter$.subscribe(tc => {
      this.treatmentCenter = tc;
      if (!this.isChineseTcSelected) {
        //this.mainControls.anesthesiologistNameLoc.reset();
        this.mainControls.chinaSupport.reset();
        this.mainControls.chinaSupportOrphan.reset();
        this.mainControls.chinaSupportMigrant.reset();
        this.mainControls.chinaSupportRegionNofund.reset();
      }
      if (!this.isIndianTcSelected) {
        this.indiaDeclarationForm.reset();
      }
      if (!this.isPhilippinesTcSelected) {
        this.mainControls.philHealth.reset();
      }
    });
    interventionTypeControlNames.forEach((controlName, index) => {
      this.mainControls[controlName].valueChanges.pipe(filter(selected => !!selected)).subscribe(_ => {
        const toReset = exclusiveInterventionTypesFor(controlName);
        toReset.forEach(interventionType => {
          this.reset(interventionType);
          this.reset(repairTypeControlNames[interventionTypeControlNames.indexOf(interventionType)]);
        });
      });
      this.mainControls[controlName].valueChanges.pipe(filter(selected => !selected)).subscribe(_ => {
        this.reset(repairTypeControlNames[index]);
      });
    });
    this.formGroup = new UntypedFormGroup(
      {
        ...this.mainControls,
        ...this.anesthesiaMediaControls,
        ...this.photoControls,
        indiaDeclarationForm: this.indiaDeclarationForm
      },
      {
        validators: [atLeastOneRequired(interventionTypeControlNames, 'surgicalOperationTypes'), this.atLeastOneChinaSupportFieldRequired()]
      }
    );

    this.saveValidationConfig = {
      controlNamesForFullValidation: SurgicalInterventionForm.fieldsCheckedOnSave,
      crossFieldErrorNames: ['surgicalOperationTypes', 'chinaSupportFields']
    };
  }

  private _displayVelopharyngealDisfunction = false;

  public get displayVelopharyngealDisfunction(): boolean {
    return this._displayVelopharyngealDisfunction;
  }

  public set displayVelopharyngealDisfunction(value: boolean) {
    const changed = this._displayVelopharyngealDisfunction !== value;
    this._displayVelopharyngealDisfunction = value;
    if (!this._displayVelopharyngealDisfunction && changed) {
      this.mainControls.velopharyngealDisfunctionRepairType.reset();
    }
  }

  get isChineseTcSelected(): boolean {
    return this.treatmentCenter?.countryId === chinaCountryId;
  }

  get displayChinaSupportOptions(): boolean {
    const tcMatches = this.isChineseTcSelected && this.treatmentCenter?.costSharePrimaryNotcovered;
    const operationTypeMatches = anyControlHasValue([
      this.mainControls.primaryLipNoseUnilateral,
      this.mainControls.primaryLipNoseBilateral,
      this.mainControls.primaryCleftPalate
    ]);
    return tcMatches && operationTypeMatches;
  }

  get isPhilippinesTcSelected(): boolean {
    return this.treatmentCenter?.countryId === philippinesCountryId;
  }

  get isIndianTcSelected(): boolean {
    return this.treatmentCenter?.countryId === indiaCountryId;
  }

  get operationDateMoment$(): Observable<moment.Moment> {
    return validValue$(this.mainControls.operationDate)
      .pipe(map(value => moment(value)))
      .pipe(distinctUntilChanged());
  }

  get operationDate$(): Observable<string> {
    return this.operationDateMoment$
      .pipe(map(value => value.format(STX_MOMENT_DATE_FORMATS.display.dateInput)))
      .pipe(distinctUntilChanged());
  }

  public get data(): SurgicalIntervention {
    const mediaToExtractFromForm = [...anesthesiaMediaParentOrderNames, ...parentOrderNames];
    const photos = FormMediaUtils.extractNewMediaFromFormGroup([this.formGroup], mediaToExtractFromForm);

    const { indiaDeclarationForm, ...formData } = this.formGroup.value;

    const isNewFile = !!indiaDeclarationForm?.fileId;
    if (isNewFile) {
      photos.push({ ...indiaDeclarationForm, parentOrderName: ParentOrderName.INDIA_DECLARATION_FORM });
    }
    return {
      ...formData,
      newFiles: photos
    };
  }

  public set data(data: SurgicalIntervention) {
    this.formGroup.patchValue(data);
    this.setMedia(data);
    this.setIndiaDeclarationForm(data);
    this.setPractitioner(data);
    this.setAnesthesiologist(data);
  }

  private setMedia(data: SurgicalIntervention): void {
    const mediaToLoadFromTreatment = [...parentOrderNames, ...anesthesiaMediaParentOrderNames];
    this.formGroup.patchValue(FormMediaUtils.getMediaForFormGroup(data, mediaToLoadFromTreatment));
  }

  private setIndiaDeclarationForm(data: SurgicalIntervention): void {
    const uploadedIndiaDeclarationForm = data.mediaResources.find(
      media => media.parentOrderName === ParentOrderName.INDIA_DECLARATION_FORM
    );
    const indiaDeclarationFormUrl = uploadedIndiaDeclarationForm?.urlList?.[0];
    this.indiaDeclarationForm.setValue(indiaDeclarationFormUrl);
  }

  private setPractitioner(data: SurgicalIntervention) {
    if (data?.practitioners?.length > 0) {
      this.mainControls.practitionerId.patchValue(data.practitioners[0].id);
    }
  }

  private setAnesthesiologist(data: SurgicalIntervention) {
    if (!!data?.anesthesiologist) {
      this.mainControls.anesthesiologistId.patchValue(data.anesthesiologist.id);
    }
  }

  private get isLip(): boolean {
    const lipControls = [
      this.mainControls.primaryLipNoseUnilateral,
      this.mainControls.primaryLipNoseBilateral,
      this.mainControls.lipNoseRevision
    ];
    return anyControlHasValue(lipControls);
  }

  private get isIntraOralMandatory(): boolean {
    const operationTypesRequiringIntraOral = [
      this.mainControls.primaryCleftPalate,
      this.mainControls.fistula,
      this.mainControls.velopharyngealDisfunction,
      this.mainControls.alveolarBoneGraft
    ];
    return anyControlHasValue(operationTypesRequiringIntraOral) || this.isNotLipTypeOtherOperation;
  }

  private get isNotLipTypeOtherOperation(): boolean {
    return (
      this.mainControls.primaryCleftPalateRepairType.value === PrimaryCleftPalateRepairType.OTHER ||
      this.mainControls.fistulaRepairType.value === FistulaRepairType.OTHER
    );
  }

  private reset(controlName: keyof MainControls): void {
    this.mainControls[controlName].reset();
  }

  private atLeastOneChinaSupportFieldRequired(): ValidatorFn {
    return (control: AbstractControl) => {
      if (this.displayChinaSupportOptions) {
        return atLeastOneRequired(chinaSupportFields, 'chinaSupportFields', 'chinaSupport', false)(control);
      } else {
        return null;
      }
    };
  }

  private isSelectedPredicate = (controlName: string) => () => !!this.formGroup.get(controlName)?.value;
}
