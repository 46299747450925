import { DataPageWithNames } from '@/src/app/shared/modules/general-commons/components/data-table/data-table.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { Item } from '@shared/models/item.model';
import { User } from '@shared/models/user/user.model';
import { StatisticsTable } from '@shared/modules/statistics/statistics.model';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { HttpUtils } from '@utils/http.utils';
import { Observable } from 'rxjs';
import { Scope } from '../models/scope.model';
import { UserData } from '../models/user-data.model';
import { UserSearchModel } from '../models/user-search.model';
import { Mime } from '@utils/file.utils';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: 'users' });
  }

  getUsers(pageSort?: PaginationSortingModel, searchFilters?: UserSearchModel): Observable<DataPageWithNames<any>> {
    if (searchFilters || pageSort) {
      const params = HttpUtils.convertToSearchParams(searchFilters, pageSort);
      return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}`, { params });
    }
    return this.httpClient.get<DataPageWithNames<any>>(`${this.baseUrl}`);
  }

  getUsersCsvFile(searchFilters?: UserSearchModel): Observable<any> {
    const headers = new HttpHeaders().set('Accept', Mime.CSV);
    if (searchFilters) {
      const params = HttpUtils.convertToSearchParams(searchFilters);
      return this.httpClient.get(`${this.baseUrl}`, { responseType: 'blob', headers, params });
    }
    return this.httpClient.get(`${this.baseUrl}`, { responseType: 'blob', headers });
  }

  getUserById(id: number): Observable<User> {
    return this.httpClient.get<User>(`${this.baseUrl}/${id}`);
  }

  getReviewerById(id: number): Observable<User> {
    return this.httpClient.get<User>(`${this.baseUrl}/${id}/reviewer`);
  }

  saveUser(user: UserData): Observable<User> {
    return this.httpClient.post<User>(`${this.baseUrl}`, user);
  }

  updateUser(user: UserData): Observable<User> {
    return this.httpClient.put<User>(`${this.baseUrl}`, user);
  }

  getRoles(): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseUrl}/roles`);
  }

  getGeoScope(): Observable<Scope[]> {
    return this.httpClient.get<Scope[]>(`${this.baseUrl}/scopes/geo`);
  }

  getOrgTreeScope(): Observable<Scope[]> {
    return this.httpClient.get<Scope[]>(`${this.baseUrl}/scopes/orgtree`);
  }

  setUserLanguage(language: string): Observable<User> {
    return this.httpClient.patch<User>(`${this.baseUrl}` + '/change-language', { lang: language });
  }

  getDomainAccess(): Observable<Scope> {
    return this.httpClient.get<Scope>(`${this.baseUrl}/scopes/domain`);
  }

  selectUserProfile(profileId: number): Observable<User> {
    return this.httpClient.post<User>(`${this.baseUrl}/profile/${profileId}`, { id: profileId });
  }

  getDashboardStatisticsTables(): Observable<StatisticsTable> {
    return this.httpClient.get<StatisticsTable>(`${this.baseUrl}/statistics/table`);
  }
}
